<template>
  <div>
    <h1>Add New Sensor</h1>
    <form @submit.prevent="handleSubmit" class="sensor-form">
      <div class="form-group">
        <label for="sensorId">Sensor ID:</label>
        <input type="text" id="sensorId" v-model="sensorId" required>
      </div>

      <div v-show="vendors.length > 0" class="form-group">
        <label for="vendorId">Vendor:</label>
        <select id="vendorId" v-model="vendorId" required>
          <option v-for="vendor in vendors" :key="vendor.id" :value="vendor.id">{{ vendor.name }}</option>
        </select>
      </div>
      
      <button type="submit">Add Sensor</button>
    </form>
  </div>
</template>

<script lang="ts">
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EGettersType, EModificationType, ESortOrderType, EVendorSortType } from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';
const VENDORS_LIMIT = 100;

export default Vue.extend({
  data() {
    return {
      sensorId: '',
      vendorId: '',
    };
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_VENDORS
    ])
  },
  created: async function () {
    try {
      await this.$store.dispatch(EModificationType.FETCH_VENDORS, { sortOrder: ESortOrderType.ASC, sortBy: EVendorSortType.DATE, limit: VENDORS_LIMIT });
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  methods: {
    handleSubmit: async function () {
      try {
        await this.$store.dispatch(EModificationType.CREATE_SENSOR, { sensorId: this.sensorId, vendorId: this.vendorId });
        this.$router.push({ name: 'sensors' }); // Navigate back to sensors list
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
  },
});
</script>

<style scoped>
.sensor-form {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust as needed */
  margin: auto; /* Center the form horizontally */
}

.form-group {
  margin-bottom: 1rem; /* Adjust spacing between form groups */
}

label {
  margin-bottom: 0.5rem; /* Adjust spacing between label and input/select */
}

input,
select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

</style>
