import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EGettersType, EModificationType, EUserType, NETWORK_ERROR_MESSAGE, SERVER_UNREACHABLE, SERVICE_URLS, UNEXPECTED_ERROR_PREFIX } from '../globals';
import Vue from 'vue';
import Vuex from 'vuex';
import { getAccessToken, logUserOut, setAccessToken } from '../util';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isNetworkErrorOrTimeout: false,
    isAuthenticated: false,
    fcmToken: "",
    user: {
      userType: "",
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      tz: "",
      locale: "",
      createdDate: "",
      updatedDate: "",
      id: ""
    },
    animals: [],
    vendors: [],
    sensors: [],
    healthAlerts: [],
    users: [],
    vaccines: [],
    diseases: [],
    treatments: []
  },
  getters: {
    [EGettersType.GET_USER]: function (state): any {
      return state.user;
    },
    [EGettersType.GET_IS_AUTHENTICATED]: function (state): boolean {
      return state.isAuthenticated;
    },
    [EGettersType.GET_FCM_TOKEN]: function (state): string {
      return state.fcmToken;
    },
    [EGettersType.GET_VENDORS]: function (state): any {
      return state.vendors;
    },
    [EGettersType.GET_VACCINES]: function (state): any {
      return state.vaccines;
    },
    [EGettersType.GET_DISEASES]: function (state): any {
      return state.diseases;
    },
    [EGettersType.GET_TREATMENTS]: function (state): any {
      return state.treatments;
    },
    [EGettersType.GET_SENSORS]: function (state): any {
      return state.sensors;
    },
    [EGettersType.GET_ANIMALS]: function (state): any {
      return state.animals;
    },
    [EGettersType.GET_USERS]: function (state): any {
      return state.users;
    },
    [EGettersType.GET_HEALTH_ALERTS]: function (state): any {
      return state.healthAlerts;
    },
    [EGettersType.GET_USER_ROLE]: function (state: any): any {
      if (state.user) {
        return state.user.userType;
      } else {
        return '';
      }
    },
  },
  mutations: {
    [EModificationType.SET_USER]: (state, value) => {
      state.user = value;
    },
    [EModificationType.SET_ANIMALS]: (state, value) => {
      state.animals = value;
    },
    [EModificationType.SET_VENDORS]: (state, value) => {
      state.vendors = value;
    },
    [EModificationType.SET_VACCINES]: (state, value) => {
      state.vaccines = value;
    },
    [EModificationType.SET_DISEASES]: (state, value) => {
      state.diseases = value;
    },
    [EModificationType.SET_TREATMENTS]: (state, value) => {
      state.treatments = value;
    },
    [EModificationType.SET_SENSORS]: (state, value) => {
      state.sensors = value;
    },
    [EModificationType.SET_USERS]: (state, value) => {
      state.users = value;
    },
    [EModificationType.SET_HEALTH_ALERTS]: (state, value) => {
      state.healthAlerts = value;
    },
    [EModificationType.SET_IS_AUTHENTICATED]: (state, value) => {
      state.isAuthenticated = value;
    },
    [EModificationType.SET_FCM_TOKEN]: (state, value) => {
      state.fcmToken = value;
    }
  },
  actions: {
    [EModificationType.LOG_IN_WITH_LOCAL_AUTH]: async (context, { password, email }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        // Generate token id and secret
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAuthURI}/login`, {
          method: "POST",
          body: JSON.stringify({
            email,
            password
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status === 200) {
          const { token } = await queryResponse.json();
          // Store auth token in persistent store
          setAccessToken(token);
          context.commit(EModificationType.SET_IS_AUTHENTICATED, true);
          context.dispatch(EModificationType.FETCH_OWN_ACCOUNT);
          return;
        } else {
          const queryResponseData = await queryResponse.json();
          if (queryResponseData.error) {
            throw new Error(queryResponseData.error); // TODO: Make 'Invalid token or secret more user friendly'
          } else {
            // TODO: How to alert engineers of this?
            throw new Error('Unknown error');
          }
        }
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.SEND_PASSWORD_RESET_LINK]: async (context, { email }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        // Generate token id and secret
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAuthURI}/forgot-password`, {
          method: "POST",
          body: JSON.stringify({
            email
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status === 200) {
          return;
        } else {
          const queryResponseData = await queryResponse.json();
          if (queryResponseData.error) {
            throw new Error(queryResponseData.error);
          } else {
            // TODO: How to alert engineers of this?
            throw new Error('Unknown error');
          }
        }
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.RESET_PASSWORD]: async (context, { newPassword, token }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        // Generate token id and secret
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAuthURI}/reset-password`, {
          method: "POST",
          body: JSON.stringify({
            newPassword,
            token
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status === 200) {
          return;
        } else {
          const queryResponseData = await queryResponse.json();
          if (queryResponseData.error) {
            throw new Error(queryResponseData.error);
          } else {
            // TODO: How to alert engineers of this?
            throw new Error('Unknown error');
          }
        }
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.LOG_OUT]: async (context) => {
      try {

        // if (context.state.isNetworkErrorOrTimeout) {
        //   context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        // }
        //
        // const accessToken = getAccessToken();
        //
        // // Fetch account
        // const queryResponse = await fetch(`${SERVICE_URLS.lmsAuthURI}/logout`, {
        //   method: "POST",
        //   headers: {
        //     "Authorization": `Bearer ${accessToken}`,
        //     "Content-Type": "application/json"
        //   }
        // });
        //
        // // Check status
        // if (queryResponse.status !== 200) {
        //   if (queryResponse.ok === false) {
        //     if (queryResponse.status === 503) {
        //       await context.dispatch(EModificationType.LOG_OUT);
        //     } else {
        //       const bodyResponse = await queryResponse.json();
        //       throw new Error(bodyResponse.error);
        //     }
        //   } else {
        //     throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        //   }
        // }

        logUserOut();
        return true;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_OWN_ACCOUNT]: async (context) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();
    
        // Fetch account
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/users/getOwnAccount`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_OWN_ACCOUNT);
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const accountData = await queryResponse.json();
        const account = accountData.user;
        context.commit(EModificationType.SET_USER, account);
        return account;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_USERS]: async (context, { skip, sortOrder, sortBy, limit, userType }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/users/getUsers?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        if (userType) {
          fetchURI = `${fetchURI}&userType=${userType}`;
        }

        // Fetch users
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_USERS, { skip, sortOrder, sortBy, limit, userType });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, users } = result;
        context.commit(EModificationType.SET_USERS, users);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_ANIMALS]: async (context, { sortOrder, sortBy, limit, skip, isInGestation, healthStatus, animalSpecies, sex, eid }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/animals/getAnimals?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`;

        if (healthStatus) {
          fetchURI = `${fetchURI}&healthStatus=${healthStatus}`;
        }

        if (sex) {
          fetchURI = `${fetchURI}&sex=${sex}`;
        }

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        if (isInGestation) {
          fetchURI = `${fetchURI}&isInGestation=${isInGestation}`;
        }

        if (eid) {
          fetchURI = `${fetchURI}&eid=${eid}`;
        }

        if (animalSpecies) {
          fetchURI = `${fetchURI}&animalSpecies=${animalSpecies}`;
        }
    
        // Fetch animals
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_ANIMALS, { sortOrder, sortBy, limit, skip, isInGestation, healthStatus, animalSpecies, sex, eid });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, animals } = result;
        context.commit(EModificationType.SET_ANIMALS, animals);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_BREED_BY_ID]: async (context, { breedId }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/breeds/getBreedById?breedId=${breedId}`;
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_BREED_BY_ID, { breedId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {breed} = await queryResponse.json();
        return breed;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_ANIMAL_BY_ID]: async (context, { animalId }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/animals/getAnimalById?animalId=${animalId}`;
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_BREED_BY_ID, { animalId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {animal} = await queryResponse.json();
        return animal;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_ANIMAL_ANCESTRY]: async (context, { animalId }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/animals/getAncestry?animalId=${animalId}`;
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_ANIMAL_ANCESTRY, { animalId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {animal} = await queryResponse.json();
        return animal;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_NUMBER_OF_OFFSPRING]: async (context, { animalId }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/animals/getNumberOfOffspring?animalId=${animalId}`;
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_NUMBER_OF_OFFSPRING, { animalId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {numberOfOffspring} = await queryResponse.json();
        return numberOfOffspring;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_VENDOR_BY_ID]: async (context, { vendorId }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/vendors/getVendorById?vendorId=${vendorId}`;
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_VENDOR_BY_ID, { vendorId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {vendor} = await queryResponse.json();
        return vendor;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_SENSOR_BY_ID]: async (context, { sensorId }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/sensors/getSensorById?sensorId=${sensorId}`;
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_SENSOR_BY_ID, { sensorId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {sensor} = await queryResponse.json();
        return sensor;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_TAG_BY_ID]: async (context, { tagId }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/tags/getTagById?tagId=${tagId}`;
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_TAG_BY_ID, { tagId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {tag} = await queryResponse.json();
        return tag;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_USER_BY_ID]: async (context, { userId }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/users/getUserById?userId=${userId}`;
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_USER_BY_ID, { userId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {user} = await queryResponse.json();
        return user;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_VENDORS]: async (context, { skip, sortOrder, sortBy, limit }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/vendors/getVendors?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`;

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }
    
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_VENDORS, { skip, sortOrder, sortBy, limit });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, vendors } = result;
        context.commit(EModificationType.SET_VENDORS, vendors);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },

    [EModificationType.LINK_SENSOR_TO_ANIMAL_USING_EID]: async (context, { sensorId, animalEID }) => {
      try {
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/sensors/linkSensorToAnimalUsingEID`;

        const response = await fetch(fetchURI, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ sensorId, animalEID })
        });
    
        if (response.status !== 200) {
          if (!response.ok) {
            if (response.status === 503) {
              await context.dispatch(EModificationType.LINK_SENSOR_TO_ANIMAL_USING_EID, { sensorId, animalEID });
            } else {
              const bodyResponse = await response.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const result = await response.json();
        return result;
    
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },

    [EModificationType.FETCH_SENSORS]: async (context, { skip, sortOrder, sortBy, limit, isAssignedAnAnimal }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/sensors/getSensors?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`;

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        if (isAssignedAnAnimal) {
          fetchURI = `${fetchURI}&isAssignedAnAnimal=${isAssignedAnAnimal}`;
        }

        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_SENSORS, { skip, sortOrder, sortBy, limit });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, sensors } = result;
        context.commit(EModificationType.SET_SENSORS, sensors);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_HEALTH_ALERTS]: async (context, { skip, startDate, endDate, sortOrder, sortBy, limit, vitalType, isRead }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/healthAlerts/getHealthAlerts?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`;

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        if (startDate) {
          fetchURI = `${fetchURI}&startDate=${startDate}`;
        }

        if (endDate) {
          fetchURI = `${fetchURI}&endDate=${endDate}`;
        }

        if (vitalType) {
          fetchURI = `${fetchURI}&vitalType=${vitalType}`;
        }

        if (isRead) {
          fetchURI = `${fetchURI}&isRead=${isRead}`;
        }
    
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_HEALTH_ALERTS, { skip, startDate, endDate, sortOrder, sortBy, limit, vitalType, isRead });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { healthAlerts, count } = result;
        context.commit(EModificationType.SET_HEALTH_ALERTS, healthAlerts);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_REPORTS]: async (context, { startDate, endDate, animalId, reportType, skip, sortOrder, sortBy, limit }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/reports/getReports?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}&animalId=${animalId}`;

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        if (reportType) {
          fetchURI = `${fetchURI}&reportType=${reportType}`;
        }

        if (startDate) {
          fetchURI = `${fetchURI}&startDate=${startDate}`;
        }

        if (endDate) {
          fetchURI = `${fetchURI}&endDate=${endDate}`;
        }
    
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_REPORTS, { startDate, endDate, animalId, reportType, skip, sortOrder, sortBy, limit });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        return result;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_DISEASES]: async (context, { skip, sortOrder, sortBy, limit, AnimalSpeciesID }) => {
      try {
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/diseases/getDiseases?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`;

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        if (AnimalSpeciesID) {
          fetchURI = `${fetchURI}&AnimalSpeciesID=${AnimalSpeciesID}`;
        }
    
        // Fetch diseases
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_DISEASES, { skip, sortOrder, sortBy, limit, AnimalSpeciesID });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, diseases } = result;
        context.commit(EModificationType.SET_DISEASES, diseases);
        return count;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_TREATMENTS]: async (context, { skip, sortOrder, sortBy, limit, AnimalSpeciesID }) => {
      try {
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/treatments/getTreatments?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`;

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        if (AnimalSpeciesID) {
          fetchURI = `${fetchURI}&AnimalSpeciesID=${AnimalSpeciesID}`;
        }
    
        // Fetch treatments
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_TREATMENTS, { skip, sortOrder, sortBy, limit, AnimalSpeciesID });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, treatments } = result;
        context.commit(EModificationType.SET_TREATMENTS, treatments);
        return count;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_VACCINES]: async (context, { skip, sortOrder, sortBy, limit }) => {
      try {
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.lmsAppURI}/vaccines/getVaccines?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`;

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }
    
        // Fetch vaccines
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_VACCINES, { skip, sortOrder, sortBy, limit });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, vaccines } = result;
        context.commit(EModificationType.SET_VACCINES, vaccines);
        return count;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_HOURLY_VITALS]: async (context, { vitalType, animalId, date, limit }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/vitals/getHourlyVitals?vitalType=${vitalType}&animalId=${animalId}&date=${date}&limit=${limit}`;
    
        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.ok === false) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_HOURLY_VITALS, { vitalType, animalId, date });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {vitals} = await queryResponse.json();
        return vitals;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_DAILY_VITALS]: async (context, { vitalType, animalId, month, year }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/vitals/getDailyVitals?vitalType=${vitalType}&animalId=${animalId}&month=${month}&year=${year}`;

        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.ok === false) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_DAILY_VITALS, { vitalType, animalId, month, year });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {vitals} = await queryResponse.json();
        return vitals;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_ANIMAL_STATS]: async (context) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        const accessToken = getAccessToken();

        const fetchURI = `${SERVICE_URLS.lmsAppURI}/animals/getAnimalStats`;

        // Fetch breeds
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_ANIMAL_STATS);
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const {animalStats} = await queryResponse.json();
        return animalStats;

      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_USER]: async (context, { firstName, lastName, email, phone, userType }) => {
      try {
        const accessToken = getAccessToken();

        let route = '';

        if (userType === EUserType.ADMIN) {
          route = 'createAdmin';
        } else if (userType === EUserType.VET) {
          route = 'createVet';
        } else if (userType === EUserType.FARMER) {
          route = 'createFarmer';
        } else if (userType === EUserType.FARM_MANAGER) {
          route = 'createFarmManager';
        }

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/users/${route}`, {
          method: "POST",
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_USER, { firstName, lastName, email, phone, userType });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_BREED]: async (context, { name, animalSpecies }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/breeds/createBreed`, {
          method: "POST",
          body: JSON.stringify({
            name,
            animalSpecies
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_BREED, { name, animalSpecies });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_VENDOR]: async (context, { name, email, phone }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/vendors/createVendor`, {
          method: "POST",
          body: JSON.stringify({
            name,
            email,
            phone
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_VENDOR, { name, email, phone });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_ANIMAL]: async (context, { name, breedId, animalSpecies, eidShortName, dob, sex, sireEIDShortName, damEIDShortName }) => {
      try {
        const accessToken = getAccessToken();

        const input: any = {
          eidShortName,
          sex,
          dob,
          breedId,
          animalSpecies,
        }

        if (sireEIDShortName) {
          input.sireEIDShortName = sireEIDShortName;
        }

        if (name) {
          input.name = name;
        }

        if (damEIDShortName) {
          input.damEIDShortName = damEIDShortName;
        }

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/animals/createAnimal`, {
          method: "POST",
          body: JSON.stringify(input),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_ANIMAL, { breedId, animalSpecies, eidShortName, dob, sex });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_SENSOR]: async (context, { sensorId, vendorId }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/sensors/createSensor`, {
          method: "POST",
          body: JSON.stringify({
            sensorId,
            vendorId
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_SENSOR, { sensorId, vendorId });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_TAG]: async (context, { eid, eidShortName }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/tags/createTag`, {
          method: "POST",
          body: JSON.stringify({
            eid,
            eidShortName
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_TAG, { eid, eidShortName });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_TREATMENT_REPORT]: async (context, { notes, animalId, treatmentId, diseaseId }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/reports/createTreatmentReport`, {
          method: "POST",
          body: JSON.stringify({
            notes,
            animalId,
            treatmentId,
            diseaseId
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_TREATMENT_REPORT, { notes, animalId, treatmentId, diseaseId });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_VACCINATION_REPORT]: async (context, { notes, animalId, vaccineId }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/reports/createVaccinationReport`, {
          method: "POST",
          body: JSON.stringify({
            notes,
            animalId,
            vaccineId
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_VACCINATION_REPORT, { notes, animalId, vaccineId });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_ROUTINE_CHECK_REPORT]: async (context, { notes, animalId, healthStatus }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/reports/createRoutineCheckReport`, {
          method: "POST",
          body: JSON.stringify({
            notes,
            animalId,
            healthStatus
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_ROUTINE_CHECK_REPORT, { notes, animalId, healthStatus });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.MARK_HEALTH_ALERT_AS_READ]: async (context, { healthAlertId }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/healthAlerts/markHealthAlertAsRead`, {
          method: "PUT",
          body: JSON.stringify({
            healthAlertId
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.MARK_HEALTH_ALERT_AS_READ, { healthAlertId });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.UPDATE_HEALTH_STATUS]: async (context, { animalId, healthStatus, dod }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/animals/updateHealthStatus`, {
          method: "PUT",
          body: JSON.stringify({
            animalId,
            healthStatus,
            dod
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.UPDATE_HEALTH_STATUS, { animalId, healthStatus, dod });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.UPDATE_GESTATION_STATUS]: async (context, { animalId, isInGestation, gestationStartDate }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/animals/updateGestationStatus`, {
          method: "PUT",
          body: JSON.stringify({
            animalId,
            isInGestation,
            gestationStartDate
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.UPDATE_GESTATION_STATUS, { animalId, isInGestation, gestationStartDate });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.UPDATE_TREATMENT_STATUS]: async (context, { animalId, isUnderTreatment }) => {
      try {
        const accessToken = getAccessToken();

        // Create card order
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/animals/updateTreatmentStatus`, {
          method: "PUT",
          body: JSON.stringify({
            animalId,
            isUnderTreatment
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.UPDATE_TREATMENT_STATUS, { animalId, isUnderTreatment });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT]: async (context, { firebaseDeviceToken }) => {
      try {

        const accessToken = getAccessToken();

        // Update firebase device token
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/users/setFirebaseDeviceTokenOnOwnAccount`, {
          method: "PUT",
          body: JSON.stringify({
            firebaseDeviceToken
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.status !== 304) {
            if (queryResponse.ok === false) {
              if (queryResponse.status === 503) {
                await context.dispatch(EModificationType.SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT, { firebaseDeviceToken });
              } else {
                const bodyResponse = await queryResponse.json();
                throw new Error(bodyResponse.error);
              }
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.UPDATE_ANIMAL_WEIGHT]: async (context, { animalId, weight }) => {
      try {
        const accessToken = getAccessToken();

        // Update animal weight
        const queryResponse = await fetch(`${SERVICE_URLS.lmsAppURI}/animals/updateAnimalWeight`, {
          method: "PUT",
          body: JSON.stringify({
            animalId,
            weight
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
  
        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.UPDATE_ANIMAL_WEIGHT, { animalId, weight });
          } else {
            if (queryResponse.ok === false) {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        return;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    }
  }
})
