<template>
  <div class="report-list-container">
    <h2>Report List For EID: {{ animal.eid }}</h2>
    <router-link v-show="shouldShow([userType.farmer, userType.admin])" :to="{ name: 'reportAdd', params: { animal: animal } }">
      <button class="add-report-btn">Create New Report</button>
    </router-link>
    <div class="filters">
      <div class="filter-item">
        <label for="start-date">Start Date:</label>
        <input type="date" id="start-date" v-model="startDate" @change="fetchReports">
      </div>
      <div class="filter-item">
        <label for="end-date">End Date:</label>
        <input type="date" id="end-date" v-model="endDate" @change="fetchReports">
      </div>
    </div>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div v-else>
      <table v-if="reports.length > 0" class="report-table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Notes</th>
          <th>Diagnosis</th>
          <th>Drug/Vaccine</th>
          <th>Weight (KG)</th>
          <th>Created By</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="report in reports" :key="report.id">
          <td>{{ formattedDate(report.createdDate) }}</td>
          <td>{{ report.reportType }}</td>
          <td>{{ report.notes }}</td>
          <td>{{ report.diseaseId?.DiseaseDescription || 'N/A' }}</td>
          <td>{{ report.vaccineId?.CommonName || report.treatmentId?.TreatmentTypeDescription || 'N/A' }}</td>
          <td>{{ report.weight || 'N/A' }}</td>
          <td>
            {{ report.createdBy ? `${report.createdBy.firstName} ${report.createdBy.lastName}` : 'N/A' }}
          </td>
        </tr>
        </tbody>
      </table>
      <p v-else class="no-reports-message">There are no reports for this animal (EID: {{ animal.eid }}).</p>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage * itemsPerPage >= totalDocumentCount">Next</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS,
  DEFAULT_LIST_LIMIT,
  EGettersType,
  EModificationType,
  EReportSortType,
  ESortOrderType,
  EUserType
} from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';

   interface IUser {
     firstName: string;
     lastName: string;
   }

   interface IAnimal {
    eid: string;
   }

   interface IVaccine {
    CommonName: string;
   }

   interface ITreatment {
    TreatmentTypeDescription: string;
   }

   interface IDisease {
    DiseaseDescription: string;
   }

   interface IReport {
     id: string;
     notes: string;
     reportType: string;
     vaccineId?: IVaccine;
     treatmentId?: ITreatment;
     createdBy: IUser;
     diseaseId?: IDisease;
     weight?: number;
     createdDate: string;
   }

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      animal: {} as IAnimal,
      isLoading: false,
      reports: [] as IReport[],
      currentPage: 1,
      totalDocumentCount: 0,
      itemsPerPage: DEFAULT_LIST_LIMIT,
      startDate: '',
      endDate: '',
      userType: {
        farmer: EUserType.FARMER,
        admin: EUserType.ADMIN,
        vet: EUserType.VET,
        farmManager: EUserType.FARM_MANAGER
      }
    };
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_USER,
      EGettersType.GET_USER_ROLE
    ])
  },
  created: async function () {
    this.fetchReports();
    try {
      await this.fetchAnimal();
      this.fetchReports();
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  methods: {
    async fetchAnimal() {
      try {
        this.animal = await this.$store.dispatch(EModificationType.FETCH_ANIMAL_BY_ID, {animalId: this.id});
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    fetchReports() {
      this.isLoading = true;
      this.$store.dispatch(EModificationType.FETCH_REPORTS, {
        animalId: this.id,
        sortOrder: ESortOrderType.DESC,
        sortBy: EReportSortType.DATE,
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage,
        startDate: this.startDate,
        endDate: this.endDate
      }).then((response) => {
        this.reports = response.reports;
        this.totalDocumentCount = response.count;
        this.isLoading = false;
        if (this.reports.length === 0) {
          this.currentPage = 1;
        }
      }).catch(e => {
        this.isLoading = false;
        console.log('Show on interface: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      });
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.totalDocumentCount) {
        this.currentPage++;
        this.fetchReports();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchReports();
      }
    },
    shouldShow(listOfUserType: Array<any>) {
      return listOfUserType.indexOf(this.userRole) > -1;
    },
    formattedDate(date: string | Date) {
      const reportDate = new Date(date);
      return reportDate.toLocaleDateString(this.user.locale, {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit', second: '2-digit',
        hour12: false
      });
    },
  }
});
</script>

<style scoped>
.report-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.add-report-btn {
  
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-report-btn:hover {
  background-color: #1db674;
}

.filters {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;
}

.filter-item label {
  margin-right: 10px;
}

input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
}

.report-table th,
.report-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 2px solid #666666;
}

.report-table th {
  background-color: #f4f4f4;
}

.loading {
  
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
}

.no-reports-message {
  
  margin: 100px 0;
  font-size: 18px;
  text-align: center;
  color: #888;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}
</style>
