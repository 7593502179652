<template>
  <div class="login-page">
    <div class="login-left">
      <div class="image-container"></div>
    </div>
    <div class="login-right">
      <div class="login-container">
        <img src="/logo.png" alt="ministry-of-agriculture" class="logo">
        <h2>ANIMAL HEALTH MONITORING SYSTEM</h2>
        <form @submit.prevent="onLogin">
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="email" required placeholder="Enter your email">
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" id="password" v-model="password" required placeholder="Enter your password">
          </div>
          <div class="form-actions">
            <button type="submit" class="login-button">Login</button>
            <router-link to="/forgot-password" class="forgot-password">Forgot Password?</router-link>
          </div>
        </form>
        <p v-if="error" class="error-message">{{ error }}</p>
      </div>
      <footer class="footer">
        <p>Animal Health Monitoring System © 2024 Government of Botswana. All rights reserved.</p>
        <p>Developed by Pyxias, Inc.</p>
      </footer>
    </div>
  </div>
</template>

<script>
import { EModificationType, EGettersType, DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS } from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      email: "",
      password: "",
      error: ""
    };
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_IS_AUTHENTICATED
    ])
  },
  methods: {
    onLogin: async function () {
      try {
        await this.$store.dispatch(EModificationType.LOG_IN_WITH_LOCAL_AUTH, {
          email: this.email,
          password: this.password
        });
        if (this.isAuthenticated) {
          this.$router.push('/metrics');
        }
      } catch (e) {
        this.error = e instanceof Error ? e.message : DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS;
      }
    }
  }
});
</script>

<style scoped>
.login-page {
  
  display: flex;
  height: 100vh;
}

.login-left {
  flex: 1;
  background: url('/src/assets/sheep.jpg') no-repeat center center;
  background-size: cover;
}

.login-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E8F4FA;
  position: relative;
}

.login-container {
  max-width: 400px;
  margin: 100px 0px 0px 0px;
  padding: 20px;
  border: 2px solid #666666;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: auto;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

h2 {
  
  color: #333;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #666;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-actions {
  margin-top: 20px;
  text-align: center;
}

.login-button {
  width: 100%;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}

.forgot-password {
  display: block;
  margin-top: 12px;
  color: #007bff;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 12px;
}

.footer {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: #f8f8f8;
  border-top: 2px solid #666666;
  margin-top: auto;
  font-size: 14px;
  
  color: #666;
  position: relative;
}
</style>
