<template>
  <div class="link-sensor-container">
    <h1>Link A Sensor to {{ animal.eid }}</h1>
    <div v-if="animal.sensorId">
      <p>This animal already has a sensor linked (ID: {{ animal.sensorId }})</p>
    </div>
    <div v-else>
      <h2>Available Sensors</h2>
      <ul class="sensor-list">
        <li v-for="sensor in sensors" :key="sensor.id" class="sensor-item">
          <span>Sensor ID: {{ sensor.sensorId }}</span>
          <button @click="linkSensor(sensor)" :disabled="isLinking">
            {{ isLinking ? 'Linking...' : 'Link to Animal' }}
          </button>
        </li>
      </ul>
    </div>
    <div v-if="isLinking" class="loader"></div>
  </div>
</template>

<script>
import { EModificationType, ESortOrderType, ESensorSortType, EGettersType } from "@/globals";
const NUMBER_OF_SENSORS= 5;
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      animal: {
        name: "",
        eid: "",
        dob: "",
        sensorId: null
      },
      isLinking: false
    };
  },
  created() {
    this.fetchAnimal(this.id);
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_SENSORS
    ])
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    async fetchAnimal(animalId) {
      try {
        const animal = await this.$store.dispatch(EModificationType.FETCH_ANIMAL_BY_ID, { animalId });
        this.animal = animal;
        if (!animal.sensorId) {
          this.fetchAvailableSensors();
        }
      } catch (error) {
        console.error('Error fetching animal:', error);
      }
    },
    async fetchAvailableSensors() {
      try {
        await this.$store.dispatch(EModificationType.FETCH_SENSORS, {
          isAssignedAnAnimal: false,
          sortOrder: ESortOrderType.ASC,
          sortBy: ESensorSortType.DATE,
          limit: NUMBER_OF_SENSORS,
          skip: 0
        });
      } catch (error) {
        console.error('Error fetching available sensors:', error);
      }
    },
    async linkSensor(sensor) {
      this.isLinking = true;
      try {
        await this.$store.dispatch(EModificationType.LINK_SENSOR_TO_ANIMAL_USING_EID, { 
          sensorId: sensor.sensorId, 
          animalEID: this.animal.eid 
        });
        this.$router.push(`/animals/${this.animal.id}`);
      } catch (error) {
        console.error('Error linking sensor to animal:', error);
        // Optionally, you can show an error message to the user here
      } finally {
        this.isLinking = false;
      }
    }
  }
};
</script>

<style scoped>
.link-sensor-container {
  max-width: 800px;
  margin: 0 auto;
}

.sensor-list {
  list-style-type: none;
  padding: 0;
}

.sensor-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
